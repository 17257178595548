import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Device, Gateway, User } from '../App';
import { Grid, LinearProgress } from '@material-ui/core';
import { checkSwitchModeForDevice, setSwitchModeForDevice } from '../networking/APIClient';

export default function DeviceCard(props: Props) {
  const classes = useStyles();

  let content;
  if (props.device) {
    let textContent = <div onClick={() => onCardClicked(props)}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <div className={classes.statusText}>
            {getTextStatus(props.device)}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.statusText} variant="body2" component="p" align="center">
            <b>{props.device.best_effort_name}</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
    content = <CardContent>
      {textContent}
    </CardContent>
  }
  return (
    <Card className={`${classes.root} ${getColorClassName(classes, props)}`}>
      {content}
      {props.device !== null ? addProgressContent(props.device, classes) : null}
    </Card>
  );
}

function getTextStatus(device: Device) {
  let text = " "

  const switchThermostatActive = device.switch_thermostat_active === "1"
  if (device.switch_mode === "1") {
    if (switchThermostatActive) {
      text = "Comf"
    } else {
      text = "On"
    }
  } else if (device.switch_mode === "0") {
    if (switchThermostatActive) {
      text = "Eco"
    } else {
      text = "Off"
    }
  } else {
    text = "-"
  }

  const statusRequestNotAnswered = device.status_request_not_answered === "1"
  if (statusRequestNotAnswered) {
    text = "Offline"
  }

  return <Typography variant="h6">{text}</Typography>
}

function addProgressContent(device: Device, classes: any) {
  const isUpdating = device.isUpdating

  let content;
  if (isUpdating) {
    content = <LinearProgress className={classes.progress}/>
  } else {
    content = <div className={classes.invisibleProgress}></div>
  }

  return content

}

async function onCardClicked(props: Props) {
  if (props.device !== null) {
    // Do not send switch_mode for devices that do not support this
    if (props.device.switch_mode === null || props.device.switch_mode  === undefined) {
      console.log("Switch mode unsupported for: " + props.device.best_effort_name)
      return
    }
    const isEnabled = props.device.switch_mode === "1"
    props.device.isUpdating = true;
    props.updateDeviceForGateway(props.device, props.gateway)

    const newSwitchModeValue = isEnabled ? "0" : "1";

    // Set local
    props.device.switch_mode = newSwitchModeValue

    // Send
    const bpapi_status = await setSwitchModeForDevice(props.user, props.device.bpapi_device_id, newSwitchModeValue)
    console.log("Status: " + bpapi_status)
    let tries = 0
    checkProperty(props, newSwitchModeValue, tries, props.updateDeviceForGateway(props.device, props.gateway))

  }
}

function checkProperty(props: Props, expectedSwitchModeValue: String, tries : number, updateDeviceForGateway : Function) {

  setTimeout(async () => {
    if (props.device !== null) {
      const actualSwitchMode = await checkSwitchModeForDevice(props.user, props.device.bpapi_device_id)
      if (tries >= 5) {
        console.log("Property check...Timeout!")
        props.device.switch_mode = actualSwitchMode
        props.device.isUpdating = false;
        props.updateDeviceForGateway(props.device, props.gateway)
        return
      }

      if (actualSwitchMode === expectedSwitchModeValue) {
        console.log("Property check...Done!")
        props.device.switch_mode = actualSwitchMode
        props.device.isUpdating = false;
        props.updateDeviceForGateway(props.device, props.gateway)
      } else {
        console.log("Property check..." + actualSwitchMode)
        tries++
        checkProperty(props, expectedSwitchModeValue, tries, updateDeviceForGateway)
      }
    }
  }, 1000)
}

function getColorClassName(classes: any, props: Props) : string {
  let colorClassName = ""
  const device = props.device
  if (device) {
    const switchThermostatActive = device.switch_thermostat_active === "1"
    if (device.switch_mode === "1") {
      if (switchThermostatActive) {
        colorClassName = classes.comfort
      } else {
        const isLight = device.product_code === "CTMmTouchDim"
        if (isLight) {
          colorClassName = classes.light
        } else {
          colorClassName = classes.on
        }
      }
    } else if (device.switch_mode === "0") {
      if (switchThermostatActive) {
        colorClassName = classes.eco
      } else {
        colorClassName = classes.off
      }
    } else {
      colorClassName = classes.neutral
    }

    const statusRequestNotAnswered = device.status_request_not_answered === "1"
    if (statusRequestNotAnswered) {
      colorClassName = classes.off
    }
  }
  
  return colorClassName
}

interface Props {
  user: User
  updateDeviceForGateway: Function
  gateway: Gateway
  device: Device | null
}

export enum CardType {
  Exported, Imported, Produced, CountRegulated
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 2,
      textAlign: "left",
    },
    on: {
      backgroundColor: "#579458"
    },
    off: {
      backgroundColor: "#919191"
    },
    comfort: {
      backgroundColor: "#d97f33"
    },
    eco: {
      backgroundColor: "#5C9DAD"
    },
    neutral: {
      backgroundColor: "#5B7886"
    },
    light: {
      backgroundColor: "#d4ad41"
    },
    regulated: {
      backgroundColor: "#ab5b9f"
    },
    secured: {
      backgroundColor: "#c7514c"
    },
    progress: {
      textAlign: "end",
      background: "white",
    },
    statusText: {
      textAlign: "center",
      color: "white"
    },
    nameText: {
      textAlign: "center",
      color: "white"
    },
    invisibleProgress: {
      height: 5,
    }
  }),
);