import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InstallationsIcon from '@material-ui/icons/Router';
import ProductsIcon from '@material-ui/icons/Timeline';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import * as Colors from '../colors'

interface AppBarProps {
  content: any
  openPage: any
  handleLogout: any
  currentPage: string
}

function handleLogout(props: AppBarProps) {
  props.handleLogout();
}

function MyAppBar(props: AppBarProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function isSelected(pageName: String) {
    return pageName === props.currentPage
  }

  function getIconComponent(pageName: String) {
    const iconClassName = isSelected(pageName) ? classes.selected : classes.root
    switch (pageName) {
      case "Dashboard":
        return <DashboardIcon className={iconClassName}/>
      case "Installations":
        return <InstallationsIcon className={iconClassName}/>
      case "Products":
        return <ProductsIcon className={iconClassName}/>
    }
  }

  function addMenuItem(pageName: String) {
    return (
      <ListItem button selected={isSelected(pageName)} onClick={() => props.openPage(pageName.toLowerCase())}>
        <ListItemIcon>
          {getIconComponent(pageName)}
        </ListItemIcon>
        <ListItemText primary={pageName}/>
      </ListItem>
    )
  }

  const drawer = (
    <div>
      <div className={classes.root} />
      {addMenuItem("Dashboard")}
      {/* addMenuItem("Installations") */}
      {/* addMenuItem("Products") */}

      <ListItem button className={classes.logout} onClick={() => handleLogout(props)} >
        <ListItemIcon><LogoutIcon className={classes.logout} /></ListItemIcon>
        <ListItemText primary="Logout"/>
      </ListItem>
      <Divider />
    </div>
  );

  // Todo: What does this do? important?
  //const { window } = props;
  //const container = window !== undefined ? () => window().document.body : undefined;
  const container = undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.currentPage}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.content}
      </main>
    </div>
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logout: {
    color: Colors.SECURED,
  },
  selected: {
    color: "white",
  }
}));

export default MyAppBar;