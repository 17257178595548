import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { User, Customer, Device, Gateway } from '../App';
import MyAppBar from '../components/MyAppBar';
import { Paper, Grid } from '@material-ui/core';
import DeviceCard from '../components/DeviceCard';

function Dashboard(props: IDashboardProps) {
  const classes = useGridStyles();

  // Version 0.0.2
  useEffect(() => console.log("v0.0.2"), []);

  // Delete local storage cache once the Dashboard is rendering.
  useEffect(() => {
    localStorage.removeItem("slwaUser")
    localStorage.removeItem("slwaCustomer")
    localStorage.removeItem("slwaGateways")
  }, []);

  // Start periodic app data fetching
  useEffect(() => props.startPeriodicDeviceDataFetching(), [])

  function setupCustomerContent(customer: Customer) {
    const content =
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5" align="left">{customer.best_effort_name}</Typography>
          </Paper>
        </Grid>
      </Grid>

    return content
  }

  function setupGatewaysContent(gateways: Gateway[]) {

    const content =
      <Grid container justify="center">
        {gateways.map((gateway) => {
          if (gateway.devices === undefined) {
            return null
          }

          return (
            <Grid key={gateway.bpapi_gateway_id} item xs={12} md={6}>
              <Paper className={classes.paper}>
                <Typography variant="h5" align="left">{gateway.best_effort_name}</Typography>
                <br />
                <Grid container spacing={1}>
                  {gateway.devices?.filter(device => device.user_invisible !== "1").map((device) => (
                    <Grid key={device.bpapi_device_id} item xs={6} md={6} lg={4}>
                      <DeviceCard user={props.user} updateDeviceForGateway={props.updateDeviceForGateway} device={device} gateway={gateway} />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          )
        })}
      </Grid>

    return content
  }

  let customerContent;
  if (props.customer != null) {
    customerContent = setupCustomerContent(props.customer)
  }

  let gatewaysContent;
  if (props.gateways != null) {
    gatewaysContent = setupGatewaysContent(props.gateways)
  }

  const content = <div>
    {customerContent}
    {gatewaysContent}
  </div>

  return (
    <MyAppBar currentPage="Dashboard" handleLogout={props.handleLogout} openPage={props.openPage} content={content} />
  );
}

interface IDashboardProps {
  user: User
  customer: Customer
  setCustomer: Function
  gateways: Gateway[]
  setGateways: Function
  handleLogout: Function,
  updateDeviceForGateway: Function,
  startPeriodicDeviceDataFetching: Function,
  openPage: Function,
}

export interface IOverviewData {
  devices: Device[]
  statistics: Statistic[]
}

export interface Statistic {
  property_name: string
  sum: number
  average: number
  min: number
  max: number
}

const useGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: 10,
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);

export default Dashboard;
