import { User } from "../App";

// Todo: Implement secret switch to change between prod and test
const apiUrl = 'https://api.connome.com/api/'

export interface HTTPRequest {
    method?: string;
    query: string;
    body?: string;
    user: User;
  }

  export interface CustomerAndGateways {
      customer: any,
      gateways: any
  }

function fetchData(request: HTTPRequest) {
    const method = request.method;
    const query = request.query;
    const body = request.body;
    // Todo: Credentials should never be stored in JS variables.
    // Implement JWT or similar serverside.
    const credentials = {
        email: request.user.email ? request.user.email : '',
        password: request.user.password ? (request.user.password + '!!!') : ''
    };

    // We use base64 encoding (btoa) for the credentials
    const headers = new Headers({
        'Authorization': 'Basic ' + btoa(credentials.email + ':' + credentials.password),
        'Content-Type': 'application/x-www-form-urlencoded',
    });

    // Setup init
    const requestInit = {
        headers: headers,
        method: method,
        body: method === "POST" ? body : null
    };

       // Todo: Fix naive approach
       if (!query.includes('Password')) {
        console.log("Request: " + apiUrl + query);
    }

    const fetchPromise = fetch(apiUrl + query, requestInit)
        .then(response => {
            if (response.status !== 401) {
                return response.json();
            } else {
                console.error('Response status: ' + response.status);
                // Todo: Handle error
                //return request.callback(null);
            }
        })
        .catch(error => {
            console.error('Something bad happened: ' + error);
            // Todo: Handle error
            //return request.callback(null);
        });

    return fetchPromise;
}

export async function verifyPassword(user: User) : Promise<any> {
    const request = {
        method: "POST",
        query: 'VerifyPassword',
        body: 'email=' + user.email + '&password=' + encodeURIComponent(user.password) + '!!!',
        user: user,
    }

    const data = await fetchData(request)
    const access_granted = data.Data.bpapi_result.access_granted;
    if (access_granted === "FullAccess") {
        return request.user;
    }
    console.error("Full access not granted. Current access: " + access_granted);
    return null;
}

export async function getCustomerAndGateways(user: User) : Promise<any>{
    const request = {
        method: "GET",
        query: 'AppView/v4.0/',
        user: user,
    }

    const data = await fetchData(request)
    //const bpapi_status = data.Data.bpapi_status
    const bpapi_result = data.Data.bpapi_result
    if (bpapi_result === undefined) {
        return null;
    }

    const customerAndGateways = {
        customer: bpapi_result.customer,
        gateways: bpapi_result.gateways
    }

    return customerAndGateways;
}

export async function getAppViewForGateway(user: User, bpapi_gateway_id: number) : Promise<any> {
    const request = {
        method: "GET",
        query: 'AppView/v4.0/' + bpapi_gateway_id + '/',
        user: user,
    }

    const data = await fetchData(request)
    //const bpapi_status = data.Data.bpapi_status
    const bpapi_result = data.Data.bpapi_result
    if (bpapi_result === undefined) {
        return null;
    }

    // Todo: Return everything?
    return bpapi_result.devices;
}

export async function setSwitchModeForDevice(user: User, device_id: number, value: string) : Promise<any> {
    const request = {
        method: "GET",
        query: 'Device/' + device_id + '/AddProperty/switch_mode/' + value,
        user: user,
    }

    const data = await fetchData(request)
    const bpapi_status = data.Data.bpapi_status
    return bpapi_status
}

export async function checkSwitchModeForDevice(user: User, device_id: number) : Promise<any> {
    const request = {
        method: "GET",
        query: 'Device/' + device_id + '/Property/switch_mode/Value',
        user: user,
    }

    const data = await fetchData(request)
    //const bpapi_status = data.Data.bpapi_status
    const result = data.Data.result

    if (result === undefined) {
        return null;
    }

    return result[0];
}

export function getCustomerProperty(user: User, customerId: number, propertyName: string) {
    return getProperty(user, "Customer", customerId, propertyName);
}

export function getGatewayProperty(user: User, gatewayId: number, propertyName: string) {
    return getProperty(user, "Gateway", gatewayId, propertyName);
}

async function getProperty(user: User, parent: string, id: number, propertyName: string) {
    const request = {
        method: "GET",
        query: parent + "/" + id + "/Property/" + propertyName,
        user: user,
    }

    const data = await fetchData(request);
    return data.Data.property.Value;
}