import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { getAppViewForGateway } from './networking/APIClient';

let appDataFetcher: NodeJS.Timeout | null = null;

function App() {

  // This method is called when the user tries to refresh the page.
  // When a React app is refreshed, all the data will be deleted, and the app will no longer work.

  // To circumvent this katzenjammer, we store all relevant user data to local storage temporarily.
  // After the refresh, we then get all the items back from local storage and populate the app data with them.
  // This should result in the user staying on the same page.

  // Note that sensitive data should not be stored in local storage permanently, so we
  // delete the data in local storage as soon as the Dashboard page loads.
  window.onbeforeunload = () => {
    console.log("Reload")
    if (user === null || customer === null || gateways === null) {
      // Only store values to local storage if they actually exist.
    } else {
      localStorage.setItem("slwaUser", JSON.stringify(user))
      localStorage.setItem("slwaCustomer", JSON.stringify(customer))
      localStorage.setItem("slwaGateways", JSON.stringify(gateways))
    }
  };

  // For navigation
  const history = useHistory();

  // App state
  const [user, setUser] = useState<User | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [gateways, setGateways] = useState<Gateway[] | null>(null)

  // Theme related. We also store the user setting to local storage.
  // Media query to ask for the system color scheme. Currently unused.
  //let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(shouldUseDarkMode());
  const palette = darkMode ? 'dark' : 'light';
  const currentTheme = palette === 'dark' ? darkTheme : lightTheme;
  // Called when we click the theme button
  const handleThemeChange = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("slwaDarkModeEnabled", !darkMode ? "1" : "0")
  };

  const theme = createMuiTheme({
    palette: {
      type: palette,
      primary: currentTheme.primary,
      secondary: currentTheme.secondary
    },
    overrides: {
      // Todo: Also set unselected from here?
      MuiListItem: { // For MenuItem, change this to MuiMenuItem
        root: {
          "&$selected": {// this is to refer to the prop provided by M-UI
            backgroundColor: currentTheme.secondary.main, // updated backgroundColor
            color: "white"
          },
        },
      },
    },
  });

  function handleLogin(user: User, customer: Customer, gateways: Gateway[]) {
    setUser(user);
    setCustomer(customer);
    setGateways(gateways)
    history.push("/dashboard");
  }

  function handleLogout() {
    stopPeriodicDeviceDataFetching()
    history.push("/");
  }

  function openPage(page: string) {
    history.push(page);
  }

  // Todo: Merge with below method
  /**
   * Local update for all the devices of a Gateway.
   */
  function updateDevicesForGateway(bpapi_gateway_id: number, devices: Device[]) {
    if (gateways === null) {
      console.log("Gateways array was null")
      return;
    }
    const newGatewayArray = [...gateways]; // Copy old array
    newGatewayArray.find((gateway, i) => {
      if (gateway.bpapi_gateway_id === bpapi_gateway_id) {
        // Find the correct gateway and make a copy of it
        let gatewayCopy = newGatewayArray[i]
        // Update the devices for the gateway
        gatewayCopy.devices = devices
        // Replace the old gateway object with the new one
        newGatewayArray[i] = gatewayCopy
        // Stop searching
        return true
      }
      return false
    })
    setGateways(newGatewayArray)
  }

  // Todo: Merge with above method
  /**
   * Local update for a single device. Typically induced by a button click.
   */
  function updateDeviceForGateway(updatedDevice: Device, gateway: Gateway) {
    const newDevicesArray = gateway.devices; // Copy old array
    newDevicesArray.find((device, i) => {
      if (device.bpapi_device_id === updatedDevice.bpapi_device_id) {
        // Find the correct device and make a copy of it
        let deviceCopy = newDevicesArray[i]
        // Update the device
        deviceCopy = updatedDevice
        // Replace the old device object with the new one
        newDevicesArray[i] = deviceCopy
        // Stop searching
        return true
      }
      return false
    })

    updateDevicesForGateway(gateway.bpapi_gateway_id, newDevicesArray)

  }

  /**
   * Fetch updates for all devices from the API.
   * This is done on an interval from the Dashboard page.
   */
  function fetchDevices() {
    if (user !== null && gateways != null) {
      console.log("Refreshing devices...")
      gateways.map(async gateway => {
        const devicesForGateway = await getAppViewForGateway(user, gateway.bpapi_gateway_id)
        updateDevicesForGateway(gateway.bpapi_gateway_id, devicesForGateway)
      })
      console.log("Refreshing devices...Done!")
    }
  }

  /**
   * We fetch updated device data from the API every minute.
   */
  function startPeriodicDeviceDataFetching() {
    fetchDevices()
    appDataFetcher = setTimeout(startPeriodicDeviceDataFetching, 60000)
  }

  /**
  * We stop fetching device data when we log out.
  */
  function stopPeriodicDeviceDataFetching() {
    if (appDataFetcher) {
      clearTimeout(appDataFetcher)
    }
  }

  function getObjectFromLocalStorage(key: string) {
    const object = localStorage.getItem(key)
    if (object !== null) {
      return JSON.parse(object)
    }
    return null
  }

  function shouldUseDarkMode() {
    const darkModeEnabled = localStorage.getItem("slwaDarkModeEnabled")
    if (darkModeEnabled === "1") {
      return true;
    }
    return false;
  }

  function getDashboardRoute() {
    let content;
    if (user !== null && customer !== null && gateways !== null) {
      content = <Route exact path="/dashboard"
        render={(props) => (
          <Dashboard {...props}
            user={user}
            customer={customer}
            setCustomer={setCustomer}
            gateways={gateways}
            setGateways={setGateways}
            updateDeviceForGateway={updateDeviceForGateway}
            startPeriodicDeviceDataFetching={startPeriodicDeviceDataFetching}
            handleLogout={handleLogout}
            openPage={openPage}
          />
        )} />
    } else {
      // If we are missing some objects, it is likely because the user has refreshed the page.
      // In this case, we try to get the data from local storage. If we cannot find it, we log out.
      const currentPathname = history.location.pathname
      if (currentPathname === "/") {
        // If we are at the login page, we do not need 
      } else {
        // Try to get the data objects from local storage
        const user = getObjectFromLocalStorage("slwaUser")
        const customer = getObjectFromLocalStorage("slwaCustomer")
        const gateways = getObjectFromLocalStorage("slwaGateways")
        if (user !== null && customer !== null && gateways !== null) {
          setUser(user)
          setCustomer(customer)
          setGateways(gateways)
        } else {
          console.error("Missing data. Logging out.")
          handleLogout()
        }
      }
    }
    return content;
  }

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline makes sure that the theme also changes to root color */}
      <CssBaseline />
      <Route exact path="/"
        render={(props) => (
          <Login {...props}
            darkMode={darkMode}
            onThemeChangeClicked={handleThemeChange}
            handleLogin={handleLogin}
            openPage={openPage} />
        )} />
      {getDashboardRoute()}
    </ThemeProvider>
  );
}

export interface Customer {
  customer_id: number,
  best_effort_name: string,
}

export interface User {
  email: string;
  password: string;
}

export interface Device {
  bpapi_device_id: number,
  best_effort_name: string,
  switch_mode: string
  isUpdating: boolean
  switch_thermostat_active: string
  product_code: string
  user_invisible: string,
  status_request_not_answered: string
}

export interface Gateway {
  bpapi_gateway_id: number,
  best_effort_name: string,
  value_code: string,
  devices: Device[]
}

const lightTheme = {
  type: "light",
  primary: {
    light: '#6ba8b8',
    main: '#5C9DAD',
    dark: '#2e6b7a',
    contrastText: '#fff',
  },
  secondary: {
    light: '#d69159',
    main: '#d97f33',
    dark: '#a5591b',
    contrastText: '#fff',
  },
  text: {
    primary: '#d69159',
    secondary: '#d97f33',
    disabled: '#a5591b',
    hint: '#fff',
  },
}

const darkTheme = {
  type: "dark",
  primary: {
    light: '#5C9DAD',
    main: '#5C9DAD',
    dark: '#2e6b7a',
    contrastText: '#fff',
  },
  secondary: {
    light: '#d69159',
    main: '#d97f33',
    dark: '#a5591b',
    contrastText: '#fff',
  },
  text: {
    primary: '#d69159',
    secondary: '#d97f33',
    disabled: '#a5591b',
    hint: '#fff',
  },
}

export default App;