import React, { useState, useEffect } from 'react';
import { Button, Paper, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MoonIcon from '@material-ui/icons/NightsStay';
import SunIcon from '@material-ui/icons/WbSunny';
import LogoBlack from './../assets/sikom-black.png';
import LogoWhite from './../assets/sikom-white.png'
import { Typography } from '@material-ui/core';
import { verifyPassword, getCustomerAndGateways } from '../networking/APIClient';

function Login(props: ILoginProps) {
    const classes = useStyles();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [readyToLogin, setReadyToLogin] = useState(false);

    // Local storage saving is disabled for now
    //const storedEmail = localStorage.getItem('slwaUserEmail');
    //const storedPassword = localStorage.getItem('slwaUserPassword');
    useEffect(() => {
        /*
        if (!email && storedEmail) {
            console.log("Setting email to stored email");
            setEmail(storedEmail);
        } 

        if (!password && storedPassword) {
            console.log("Setting password to stored password");
            setPassword(storedPassword);
        }
        */

        if (email && password && readyToLogin) {
            setReadyToLogin(false);
            tryLogin(email, password);
        }
    })

    async function tryLogin(email: string, password: string) {
        // Todo Review and improve

        if (!email) {
            setEmailError(true);
        }
        if (!password) {
            setPasswordError(true);
        }

        if (email && password) {
            try {
                console.log("Logging in");
                const verifiedUser = await verifyPassword({ email: email.trim(), password: password });
                const customerAndGateways = await getCustomerAndGateways(verifiedUser)
                const customer = customerAndGateways.customer
                const gateways = customerAndGateways.gateways
                props.handleLogin(verifiedUser, customer, gateways);
            } catch (e) {
                setPasswordError(true);
                setEmailError(true);
            }
        } else {
            console.log("Email and password not set");
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <img src={props.darkMode ? LogoWhite : LogoBlack} alt="Logo" className={classes.logo} />
                <Typography variant="h4" color="primary">Living</Typography>
                <Typography variant="h6" color="primary">(Beta)</Typography>
                <form className={classes.textField}>
                    <TextField
                        id="standard-basic"
                        label="Email"
                        value={email}
                        error={emailError}
                        onChange={event => setEmail(event.target.value)} />
                    <TextField
                        id="standard-password-input"
                        label="Password"
                        value={password}
                        error={passwordError}
                        onChange={event => setPassword(event.target.value)}
                        type="password"
                        autoComplete="current-password" />
                    <Button
                        className={classes.button}
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={event => {
                            event.preventDefault();
                            setReadyToLogin(true)
                        }
                        }>Login</Button>
                </form>
                <div className={classes.margin}>
                    {/*
                    Language button disabled for now
    <Button className={classes.marginRight} color="secondary">
                        <LanguageIcon className={classes.bottomButtonIcon} />
                            Norsk
                    </Button>

                    */}

                    <Button onClick={() => props.onThemeChangeClicked()} color="secondary">
                        {props.darkMode ?
                            <SunIcon className={classes.bottomButtonIcon} /> :
                            <MoonIcon className={classes.bottomButtonIcon} />
                        }
                        {props.darkMode ? 'Light' : 'Dark'}
                    </Button>
                </div>
            </Paper>
        </div>
    );

}

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '100%',
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '15px',
    },
    paper: {
        textAlign: 'center',
        padding: '0px',
        width: '100%',
        minWidth: '300px',
        maxWidth: '400px',
    },
    textField: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '80%',
        },
        marginTop: '50px'
    },
    button: {
        width: '80%',
        marginTop: '30px',
        marginBottom: '30px'
    },
    marginLeft: {
        marginLeft: '5px'
    },
    marginRight: {
        marginRight: '5px'
    },
    margin: {
        margin: '30px'
    },
    small: {
        width: '40%'
    },
    fullWidth: {
        width: '100%'
    },
    bottomButtonIcon: {
        marginRight: '5px'
    },
    logo: {
        maxHeight: '50%',
        maxWidth: '50%',
        marginTop: '50px'
    }
}));

interface ILoginProps {
    darkMode: boolean
    onThemeChangeClicked: Function
    handleLogin: Function,
    openPage: Function
}

export default Login